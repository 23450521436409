import './TopPlayerForPeriod.css'
import Consts from './Const'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { forwardRef } from 'react'

export interface ITopPlayerForPeriod {
  Id: string
  DeltaForPeriod: number
  Points: number
  Username: string
  Fullname: string
  Avatar: string | null
  GoingUp?: boolean
}

interface ITopPlayerForPeriodProps {
  data: ITopPlayerForPeriod | undefined
  position: number
  showMedals?: boolean
}

const TopPlayerForPeriod = forwardRef<HTMLDivElement, ITopPlayerForPeriodProps>(function TopPlayerForPeriod(
  { data, position, showMedals }: ITopPlayerForPeriodProps,
  ref,
) {
  return (
    <div className="Player-container" ref={ref} style={{ opacity: data ? 1 : 0 }}>
      <div className={`Player-position${!showMedals || position > 3 ? '' : `${position}`}`}>#{position}</div>
      <div>
        <img className="Player-avatar" src={`data:image/png;base64,${data?.Avatar ?? Consts.noAvatar}`} alt="Player avatar" />
      </div>
      <div>
        <div className="Player-name">
          {/* {data.GoingUp === true && <ArrowUpwardIcon className="Player-GoingUp" />}
          {data.GoingUp === false && <ArrowDownwardIcon className="Player-GoingDown" />} */}
          {data?.Fullname} ({data?.Username})
        </div>
        <div className="Player-points">
          Delta {data?.DeltaForPeriod}p - {data?.Points}p in total
        </div>
      </div>
    </div>
  )
})

export default TopPlayerForPeriod
