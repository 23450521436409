import './dashboard.css'
import wwwwoohooo from './assets/home/wwwwoohooo.png'
import externaldashboard from './assets/home/externaldashboard.png'
import externaldashboarddialog from './assets/home/externaldashboarddialog.png'
import externaldashboardinbrowser from './assets/home/externaldashboardinbrowser.png'
import wwwrankytanktitle from './assets/home/wwwrankytanktitle.png'

function Dashboard() {
  return (
    <div className="page">
      <div className="main_page">
        <a href="/">
          <img className="woohooologo" src={wwwwoohooo} />
        </a>
      </div>

      <div className="main_page">
        <img className="rankytanktitle" src={wwwrankytanktitle} />
        <h1 className="title">Dashboard</h1>

        <p className="ptext">
          A dashboard to display the yearly and the weekly progress is available by URL.
        </p>

        <div className="textcontainer">
          <h2 className="text">
            Access the dashboard URL via the setting page by pressing the button below "Report player"
          </h2>
          <img id="img" src={externaldashboard} />
        </div>
        <div className="textcontainer">
          <h2 className="text">Copy or open the browser with the dashboard URL</h2>
          <img id="img" src={externaldashboarddialog} />
        </div>
        <div className="textcontainer">
          <h2 className="text">
            Example of how the dashboard could be shown (feel free to check the network tab or write us if you want to create a more specific dashboard)
          </h2>
          <img id="img" src={externaldashboardinbrowser} />
        </div>
      </div>

      <div className="main_page">
        <div className="footer xxx">Copyright © Woohooo 2012 - {new Date().getFullYear()}</div>
        <div className="footer xxx">
          CEO/CTO: <a href="mailto:rankytank@woohooo.dk">Nikolaj Strauss</a>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
