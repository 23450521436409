import './home.css'
import wwwwoohooo from './assets/home/wwwwoohooo.png'
import wwwrankytanktitle from './assets/home/wwwrankytanktitle.png'
import wwwrankytank30pct from './assets/home/wwwrankytank30pct.png'
import wwwappstore15pct from './assets/home/wwwappstore15pct.png'
import wwwgoogleplay15pct from './assets/home/wwwgoogleplay15pct.png'
import privacybutton from './assets/home/privacybutton.png'
import feedbackbutton from './assets/home/feedbackbutton.png'
import dashboardbutton from './assets/home/dashboardbutton.png'
import reportingbutton from './assets/home/reportingbutton.png'

function Home() {
  return (
    <div className="page">
      <div className="main_page">
        <img className="woohooologo" src={wwwwoohooo} />
      </div>

      <div className="main_page" style={{ paddingBottom: 10}}>
        <img className="rankytanktitle" src={wwwrankytanktitle} />
        <div className="rankytanksubtitle">..for handling statistics for table soccer, padel, tennis, badminton and ping pong (soon to be other sports).</div>
        <img className="rankytanklogo" src={wwwrankytank30pct} />
        <div>
          <div>
            <div className="rankytankapplestore">
              <a href="https://apps.apple.com/us/app/rankytank/id1642592019">
                <img src={wwwappstore15pct} />
              </a>
            </div>
            <div className="rankytankgoogleplay">
              <a href="https://play.google.com/store/apps/details?id=com.rankytank2">
                <img src={wwwgoogleplay15pct} />
              </a>
            </div>
          </div>
          <div>
            <div style={{ float: 'left', marginRight: 4 }}>
              <a href="/privacy">
                <img src={privacybutton} />
              </a>
            </div>
            <div style={{ float: 'none', marginLeft: 4, marginTop: 4 }}>
              <a href="/reporting">
                <img src={reportingbutton} />
              </a>
            </div>
        </div>
        <div>
            <div style={{ float: 'left', marginRight: 4 }}>
              <a href="/dashboard">
                <img src={dashboardbutton} />
              </a>
            </div>
            <div style={{ float: 'left' }}>
              <a href="/feedback">
                <img src={feedbackbutton} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="main_page">
        <div className="footer xxx">Copyright © Woohooo 2012 - {new Date().getFullYear()}</div>
        <div className="footer xxx">
          CEO/CTO: <a href="mailto:rankytank@woohooo.dk">Nikolaj Strauss</a>
        </div>
      </div>
    </div>
  )
}

export default Home
