import { useEffect, useRef, useState } from 'react'
import './App.css'
import useCaseInsensitiveSearchParams from './useCaseInsensitiveSearchParams'
import TopPlayersForYear from './TopPlayersForPeriod'
import rankytank from './assets/rankytank.png'
import snowflake1 from './assets/snow-flake-192x192.png'
import snowflake2 from './assets/snow-flake2-192x192.png'
import moment from 'moment'
import { useSignalR } from './SignalRContext'
import Consts from './Const'
import Snowfall from 'react-snowfall'
import { useImagePreloader } from './useImagePreloader'
import { ITopPlayerForPeriod } from './TopPlayerForPeriod'
import TopPlayersForLastYear from './TopPlayersForLastYear'

interface IOrganizationYearWeekPageProps {
  organizationId?: string
  activityType?: number
}

enum EntityType {
  Player = 0,
  Organization = 1,
  Match = 2,
  OrganizationPlayer = 3,
  None = 4,
  Cup = 5,
  CupPrize = 6,
  CupStructureEvent = 7,
  APIKey = 8,
  CupAttendeeEvent = 9,
  DevicePushNotification = 10,
  DevicePushNotificationQueue = 11,
  FavoritePlayer = 12,
  OrganizationLabel = 13,
  OrganizationLabelRelation = 14,
  OrganizationPlayerRequest = 15,
  Rating = 16,
  Team = 17,
  CupCoHost = 18,
  Log = 19,
  MailItem = 20,
  Token = 22,
  AppNickname = 23,
  Avatar = 24,
  HistoryItem = 25,
  Label = 26,
  LabelRelation = 27,
  OrganizationRole = 28,
  OrganizationRoleRelation = 29,
  OrganizationDepartment = 30,
  OrganizationDepartmentRelation = 31,
  Notification = 32,
  PlayerNotVerified = 33,
  PlayerRankingCurrent = 35,
  PeriodResult = 36,
  PeriodEventSettings = 37,
  PlayerActivity = 38,
  OrganizationDepartmentRelationNotVerified = 39,
  OrganizationDepartmentRelationVerified = 40,
  OrganizationDepartmentRelationVerifiedNowAndFuture = 41,
  FirstMatchIdForPlayerAndDepartment = 42,
  Event = 43,
  RatingsChanges = 44,
  Activity = 45,
  OrganizationActivityRelation = 46,
  OrganizationActivityPlayerRelation = 47,
  OrganizationActivityPlayerRelationRequest = 48,
  MatchInProgress = 49,
  EventAttendee = 50,
  EventCourt = 51,
  EventAttendeesDelta = 52,
  HistoryItems = 53,
  OrganizationTypeRelation = 54,
}

interface ILatestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast {
  RequiredSiteVersion: string
  HistoryId: string
  EntityTypesWasAffected: boolean
  EntityTypesAffected: EntityType[]
}

interface IExtraAppContainerClassForPeriod {
  className: string
  from: moment.Moment
  to: moment.Moment
}

const getNow = () => moment() //[2024, 11, 6, 6, 0])

const xmasPartyDay = 6

const ExtraAppContainerClassForPeriods = [
  {
    className: 'App-containerXMASSharedPre App-containerXMASParty10',
    from: moment([getNow().year(), 11, xmasPartyDay, 8, 30]),
    to: moment([getNow().year(), 11, xmasPartyDay, 9, 40]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASParty25',
    from: moment([getNow().year(), 11, xmasPartyDay, 9, 40]),
    to: moment([getNow().year(), 11, xmasPartyDay, 10, 50]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASParty42',
    from: moment([getNow().year(), 11, xmasPartyDay, 10, 50]),
    to: moment([getNow().year(), 11, xmasPartyDay, 12, 0]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASParty55',
    from: moment([getNow().year(), 11, xmasPartyDay, 12, 0]),
    to: moment([getNow().year(), 11, xmasPartyDay, 13, 10]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASParty67',
    from: moment([getNow().year(), 11, xmasPartyDay, 13, 10]),
    to: moment([getNow().year(), 11, xmasPartyDay, 14, 20]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASParty83',
    from: moment([getNow().year(), 11, xmasPartyDay, 14, 20]),
    to: moment([getNow().year(), 11, xmasPartyDay, 15, 30]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASParty99dot9',
    from: moment([getNow().year(), 11, xmasPartyDay, 15, 30]),
    to: moment([getNow().year(), 11, xmasPartyDay, 16, 0]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASPartyItsTime',
    from: moment([getNow().year(), 11, xmasPartyDay, 16, 0]),
    to: moment([getNow().year(), 11, xmasPartyDay + 1, 2, 0]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASPartyDone',
    from: moment([getNow().year(), 11, xmasPartyDay + 1, 2, 0]),
    to: moment([getNow().year(), 11, xmasPartyDay + 1, 7, 0]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASKidsParty',
    from: moment([getNow().year(), 11, xmasPartyDay + 2, 12, 0]),
    to: moment([getNow().year(), 11, xmasPartyDay + 2, 17, 0]),
  },
  {
    className: 'App-containerXMASSharedPre App-containerXMASPartyDone',
    from: moment([getNow().year(), 11, xmasPartyDay + 3, 8, 0]),
    to: moment([getNow().year(), 11, xmasPartyDay + 3, 16, 0]),
  },
] as IExtraAppContainerClassForPeriod[]

function getISOWeek(date: Date): number {
  const target = new Date(date.valueOf())
  target.setHours(0, 0, 0, 0)

  // Set the date to Thursday of the current week to ensure it's in the "current" ISO week
  target.setDate(target.getDate() + 3 - ((target.getDay() + 6) % 7))

  // January 4th is always in the first week
  const firstThursday = new Date(target.getFullYear(), 0, 4)
  firstThursday.setDate(firstThursday.getDate() + 3 - ((firstThursday.getDay() + 6) % 7))

  // Calculate the difference in weeks
  const weekNumber = Math.round((target.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1

  return weekNumber
}

function getISOWeekYear(date: Date): number {
  const target = new Date(date.valueOf())
  target.setDate(target.getDate() + 3 - ((target.getDay() + 6) % 7))
  return target.getFullYear()
}

function OrganizationYearWeekPage({ organizationId, activityType }: IOrganizationYearWeekPageProps) {
  const [year, setYear] = useState<number>()
  const [week, setWeek] = useState<number>()
  const [checkWeek, setCheckWeek] = useState<number>(0)
  const [latestHistoryId, setLatestHistoryId] = useState('')
  const [latestHistoryIdForAPICall, setLatestHistoryIdForAPICall] = useState('')

  const now = getNow()

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined | number

    const checkWeek = () => {
      const nowWeek = getISOWeek(new Date())
      if (nowWeek !== week) {
        setCheckWeek(x => x + 1)
        console.log(`go check week! nowWeek=${nowWeek} week=${week}`)
      }

      timerId = setTimeout(checkWeek, 30000)
    }

    checkWeek()

    return () => {
      clearTimeout(timerId)
    }
  }, [week])

  const searchParams = useCaseInsensitiveSearchParams()
  const baseUrl = searchParams['baseurl'] ?? Consts.defaultBaseUrl
  useEffect(() => {
    if (!latestHistoryId) return

    fetch(`${baseUrl}/api/getYearWeekPeriod`)
      .then(res => {
        return res.text()
      })
      .then(data => {
        setYear(+data.substring(0, 4))
        setWeek(+data.substring(6, 8))
      })
      .catch(() => {
        setYear(undefined)
        setWeek(undefined)
      })
  }, [baseUrl, latestHistoryId, checkWeek])

  useImagePreloader([
    './assets/christmaspartyloading10.jpg',
    './assets/christmaspartyloading25.jpg',
    './assets/christmaspartyloading42.jpg',
    './assets/christmaspartyloading55.jpg',
    './assets/christmaspartyloading67.jpg',
    './assets/christmaspartyloading83.jpg',
    './assets/christmaspartyloading99dot9.jpg',
    './assets/christmasparty2.png',
    './assets/christmaspartyafter.jpg',
    './assets/kidschristmasparty.jpg',
  ])

  const connection = useSignalR()
  useEffect(() => {
    if (connection) {
      const handleMessage = (user: string, message: string): void => {
        console.log(`${user} says: ${message}`)
      }

      // Subscribe to SignalR events
      connection.on('addHistoryItem', handleMessage)

      // Cleanup on unmount
      return () => {
        connection.off('addHistoryItem', handleMessage)
      }
    }
  }, [connection])

  organizationId = organizationId ?? searchParams['organizationid'] ?? '8c0193a8-bb41-4623-8199-5c8a3ecd3088' // Keylane
  activityType = activityType ?? +(searchParams['activitytype'] ?? '0')

  const snowFrom = moment([now.year(), 12 - 1, 1])
  const snowTo = moment([now.year(), 12 - 1, 27])
  const calcShowSnow = now.isBetween(snowFrom, snowTo)
  const [showSnow, setShowSnow] = useState(calcShowSnow)

  useEffect(() => {
    const startOfNextDay = getNow().add(1, 'day').startOf('day')
    const msUntilNextDay = startOfNextDay.diff(now, 'milliseconds')

    const timerId = setTimeout(() => {
      window.location.reload()
    }, msUntilNextDay)

    return () => {
      clearTimeout(timerId)
    }
  }, [latestHistoryId])

  const [appContainerClass, setAppContainerClass] = useState('App-containerXMAS')

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined | number

    const checkForExtraAppContainerClass = () => {
      const now = getNow()
      const extraAppContainerClassForPeriod = ExtraAppContainerClassForPeriods.find(x => now.isBetween(x.from, x.to, undefined, '[)'))
      console.log(`extraAppContainerClassForPeriod = ${extraAppContainerClassForPeriod} calcShowSnow=${calcShowSnow}`)
      setShowSnow(!!extraAppContainerClassForPeriod || calcShowSnow)
      const xxx = extraAppContainerClassForPeriod?.className ?? (calcShowSnow ? 'App-containerXMASSharedPre App-containerXMAS' : 'App-container')
      console.log(`xxx = ${xxx}`)
      setAppContainerClass(xxx)      

      timerId = setTimeout(checkForExtraAppContainerClass, 60000)
    }

    checkForExtraAppContainerClass()

    return () => {
      clearTimeout(timerId)
    }
  }, [])

  const [waitingForReload, setWaitingForReload] = useState(false)

  const keepalive = async () => {
    try {
      const response = await fetch(`${baseUrl}/keepalive?siteversion=${encodeURIComponent(Consts.REQUIRED_SITE_VERSION)}`)

      if (response.ok) {
        return await response.text()
      }
    } catch {}
    return undefined
  }

  const isValidVersion = (input: string) => {
    const regex = /^\d+\.\d+\.\d+$/
    return regex.test(input)
  }

  const setWaitingForReloadIfNecessary = (requiredSiteVersion: string) => {
    if (requiredSiteVersion !== Consts.REQUIRED_SITE_VERSION) {
      console.log(`requiredSiteVersion = |${requiredSiteVersion}|`)
      console.log(`Consts.REQUIRED_SITE_VERSION = |${Consts.REQUIRED_SITE_VERSION}|`)
      if (!isValidVersion(Consts.REQUIRED_SITE_VERSION)) {
        console.log('Not a valid version')
        return
      }
      setWaitingForReload(true)
    }
  }

  const fetchApiData = async (finallyCallback: () => void) => {
    try {
      if (waitingForReload) {
        const keepaliveResponse = await keepalive()
        if (!!keepaliveResponse) {
          window.location.reload()
        } else {
          console.log('API is not up yet, waiting for reload')
        }
        return
      }

      const testIfEntityTypesAreAffected = [EntityType.Match, EntityType.Player]
      const response = await fetch(
        `${baseUrl}/api/GetLatestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast?organizationId=${organizationId}&lastHistoryId=${latestHistoryIdForAPICall}&testIfEntityTypesAreAffected=${testIfEntityTypesAreAffected.join(
          ',',
        )}&siteVersion=${Consts.REQUIRED_SITE_VERSION}`,
      )

      if (response.ok) {
        const latestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast =
          (await response.json()) as ILatestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast

        setWaitingForReloadIfNecessary(latestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast.RequiredSiteVersion)
        setLatestHistoryIdForAPICall(latestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast.HistoryId)
        if (latestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast.EntityTypesWasAffected) {
          setLatestHistoryId(latestHistoryIdAndMeasureIfEntityTypesWasAffectedSinceLast.HistoryId)
        }
      } else {
        setWaitingForReload(true)
      }
    } catch {
      setWaitingForReload(true)
    } finally {
      finallyCallback()
    }
  }

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined | number

    const setTimerToFetchApiData = () => {
      timerId = setTimeout(() => fetchApiData(setTimerToFetchApiData), 10000)
    }

    fetchApiData(setTimerToFetchApiData)

    return () => {
      clearTimeout(timerId)
    }
  }, [waitingForReload, latestHistoryIdForAPICall])

  // const snowflake1x = document.createElement('img')
  // snowflake1x.src = snowflake1

  const snowflake2x = document.createElement('img')
  snowflake2x.src = snowflake2

  useEffect(() => {
    // Disable scrolling by hiding overflow
    document.body.style.overflow = 'hidden'

    return () => {
      // Re-enable scrolling when the component unmounts
      document.body.style.overflow = 'auto'
    }
  }, [])

  const [resetAnimYear, setResetAnimYear] = useState(false)
  const [resetAnimWeek, setResetAnimWeek] = useState(false)

  return (
    <div className={appContainerClass}>
      {/* {showSnow && (
        <Snowfall images={[snowflake2x]} snowflakeCount={200} radius={[5, 20]} speed={[0.5, 3]} wind={[-0.5, 2]} opacity={[0.1, 0.2]} rotationSpeed={[-1, 1]} />
      )} */}
      {showSnow && <div className="App-containerXMAS-topleft" />}
      {showSnow && <div className="App-containerXMAS-bottomright" />}
      <div className="App-logo-container">
        <img className="App-logo" src={rankytank} />
      </div>
      <div className="App-period-container">
        {waitingForReload && <div className="App-is-down">API is currently down, will be up again very soon (hopefully)</div>}
        {!waitingForReload && year && (
          <div className="Period-year-container">
            <p className="Period-title">
              <span onClick={() => setResetAnimYear(x => !x)}>Year {year}</span>
            </p>
            <TopPlayersForYear
              organizationId={organizationId}
              activityType={activityType}
              year={year}
              baseUrl={baseUrl}
              latestHistoryId={latestHistoryId}
              showMedals
              resetAnim={resetAnimYear}
              showAnim={false}
              amount={10}
            />
          </div>
        )}
        {!waitingForReload && week && (
          <div className="Period-week-container">
            <p className="Period-title">
              <span
                onClick={() => {
                  setResetAnimWeek(x => !x)
                }}
              >
                Week {week}
              </span>
            </p>
            <TopPlayersForYear
              organizationId={organizationId}
              activityType={activityType}
              year={year}
              week={week}
              baseUrl={baseUrl}
              latestHistoryId={latestHistoryId}
              amount={1000}
              resetAnim={resetAnimWeek}
              showAnim
            />
          </div>
        )}

        {!waitingForReload && <TopPlayersForLastYear organizationId={organizationId} activityType={activityType} baseUrl={baseUrl} />}
      </div>
    </div>
  )
}

export default OrganizationYearWeekPage
