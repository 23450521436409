import './TopPlayerForLastYear.css'
import Consts from './Const'
import { forwardRef } from 'react'
import { ITopPlayerForPeriod } from './TopPlayerForPeriod'

interface ITopPlayerForLastYearProps {
  data: ITopPlayerForPeriod | undefined
  position: number
}

const TopPlayerForLastYear = forwardRef<HTMLDivElement, ITopPlayerForLastYearProps>(function TopPlayerForLastYear(
  { data, position }: ITopPlayerForLastYearProps,
  ref,
) {
  return (
    <div className={`Player${position}`}>
      <div className={`Player-name${position}`}>{data?.Username}</div>
      <img className={`Player-avatar${position}`} src={`data:image/png;base64,${data?.Avatar ?? Consts.noAvatar}`} alt="Player avatar" />
    </div>
  )

  return (
    <div className="Player-container" ref={ref} style={{ opacity: data ? 1 : 0 }}>
      <div className={`Player-position${position > 3 ? '' : `${position}`}`}>#{position}</div>
      <div>
        <img className="Player-avatar" src={`data:image/png;base64,${data?.Avatar ?? Consts.noAvatar}`} alt="Player avatar" />
      </div>
      <div>
        <div className="Player-name">
          {data?.Fullname} ({data?.Username})
        </div>
        <div className="Player-points">
          Delta {data?.DeltaForPeriod}p - {data?.Points}p in total
        </div>
      </div>
    </div>
  )
})

export default TopPlayerForLastYear
