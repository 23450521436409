import './feedback.css'
import wwwwoohooo from './assets/home/wwwwoohooo.png'
import feedback from './assets/home/feedback.png'
import feedbackdialog from './assets/home/feedbackdialog.png'
import feedbackdialogsubmitted from './assets/home/feedbackdialogsubmitted.png'
import privacy5 from './assets/home/privacy5.png'
import privacy6 from './assets/home/privacy6.png'
import wwwrankytanktitle from './assets/home/wwwrankytanktitle.png'

function FeedBack() {
  return (
    <div className="page">
      <div className="main_page">
        <a href="/">
          <img className="woohooologo" src={wwwwoohooo} />
        </a>
      </div>

      <div className="main_page">
        <img className="rankytanktitle" src={wwwrankytanktitle} />
        <h1 className="title">Give Us Your Feedback – Your Voice Matters!</h1>

        <p className="ptext">
          We want to ensure that everyone feels safe, heard, and satisfied when using our app. That’s why we’ve made it easy for you to share your feedback with
          us. Whether it’s a suggestion, a concern, a bug report, or a request for a new feature, we value every message.
        </p>

        <div className="textcontainer">
          <h2 className="text">
            With our in-app feedback system, you can do a report of a bug, and feature request or other issues by pressing the button below "Report player"
          </h2>
          <img id="img" src={feedback} />
        </div>
        <div className="textcontainer">
          <h2 className="text">Select type of feed back, write your message and send it — simple and secure!</h2>
          <img id="img" src={feedbackdialog} />
        </div>
        <div className="textcontainer">
          <h2 className="text">
            Our team will review your submission and take action as soon as possible. We appreciate your input and are committed to making the app a better
            experience for everyone.
          </h2>
          <img id="img" src={feedbackdialogsubmitted} />
        </div>
      </div>

      <div className="main_page">
        <div className="footer xxx">Copyright © Woohooo 2012 - {new Date().getFullYear()}</div>
        <div className="footer xxx">
          CEO/CTO: <a href="mailto:rankytank@woohooo.dk">Nikolaj Strauss</a>
        </div>
      </div>
    </div>
  )
}

export default FeedBack
