import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import { HubConnection } from '@microsoft/signalr'
import { setupSignalRConnection } from './SignalRService'
import Consts from './Const'

type SignalRContextType = HubConnection | null

export const SignalRContext = createContext<SignalRContextType>(null)

interface SignalRProviderProps {
  children: ReactNode
}

export const SignalRProvider: React.FC<SignalRProviderProps> = ({ children }) => {
  const [connection, setConnection] = useState<SignalRContextType>(null)

  useEffect(() => {
    if (!Consts.enableSignalR) return

    const initializeSignalR = async () => {
      const conn = await setupSignalRConnection()
      setConnection(conn)
    }

    initializeSignalR()
  }, [])

  return <SignalRContext.Provider value={connection}>{children}</SignalRContext.Provider>
}

export const useSignalR = (): SignalRContextType | undefined => {
  const context = useContext(SignalRContext)
  if (!Consts.enableSignalR) return undefined
  if (context === undefined) {
    throw new Error('useSignalR must be used within a SignalRProvider')
  }
  return context
}
