import './App.css'
import { Route, Routes } from 'react-router-dom'
import Home from './home'
import OrganizationYearWeekPage from './OrganizationYearWeekPage'
import { SignalRProvider } from './SignalRContext'
import Consts from './Const'
import UgeDagPage from './UgeDagPage'
import Privacy from './privacy'
import FeedBack from './feedback'
import Reporting from './reporting'
import Dashboard from './dashboard'

function App() {
  // return <KeylanePage />

  // useEffect(() => {
  //     const connectSignalR = async () => {
  //         await setupSignalRConnection();
  //     };
  //     connectSignalR();
  // }, []);

  const routes = (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/reporting" element={<Reporting />} />
      <Route path="/feedback" element={<FeedBack />} />
      <Route path="/r/keylane" element={<OrganizationYearWeekPage organizationId="8c0193a8-bb41-4623-8199-5c8a3ecd3088" />} />
      <Route path="/r/organizationyearweekpage" element={<OrganizationYearWeekPage />} />
      <Route path="/r/ugedag" element={<UgeDagPage />} />

      {/* This only exists to support the old pattern given to Rasmus Resen https://ranky.woohooo.dk/keylane */}
      <Route path="/keylane" element={<OrganizationYearWeekPage organizationId="8c0193a8-bb41-4623-8199-5c8a3ecd3088" />} />
    </Routes>
  )

  if (!Consts.enableSignalR) return routes

  return <SignalRProvider>{routes}</SignalRProvider>
}

// function App() {
//     const [year, setYear] = useState<number>()
//     const [week, setWeek] = useState<number>()

//     const searchParams = useCaseInsensitiveSearchParams()

//     const baseUrl = searchParams['baseurl'] ?? 'https://ranky.woohooo.dk'

//     useEffect(() => {
//         fetch(`${baseUrl}/api/getYearWeekPeriod`)
//             .then((res) => {
//                 return res.text()
//             })
//             .then((data) => {
//                 setYear(+data.substring(0, 4))
//                 setWeek(+data.substring(6, 8))
//             })
//     }, [baseUrl])

//     return (
//         <div className="App-container">
//             <div className="App-logo-container">
//                 <img className="App-logo" src={rankytank} />
//             </div>
//             <div className="App-period-container">
//                 <div className="Period-year-container">
//                     <p className="Period-title">Year {year}</p>
//                     <TopPlayersForYear year={year} baseUrl={baseUrl} />
//                 </div>
//                 <div className="Period-week-container">
//                     <p className="Period-title">Week {week}</p>
//                     <TopPlayersForYear year={year} week={week} baseUrl={baseUrl} />
//                 </div>
//             </div>
//         </div>
//     )
// }

export default App
