import './reporting.css'
import wwwwoohooo from './assets/home/wwwwoohooo.png'
import feedbackbutton from './assets/home/feedbackbutton.png'
import reportplayer from './assets/home/reportplayer.png'
import reportplayerdialog from './assets/home/reportplayerdialog.png'
import reportplayerdialogsubmitted from './assets/home/reportplayerdialogsubmitted.png'
import wwwrankytanktitle from './assets/home/wwwrankytanktitle.png'

function Reporting() {
  return (
    <div className="page">
      <div className="main_page">
        <a href="/">
          <img className="woohooologo" src={wwwwoohooo} />
        </a>
      </div>

      <div className="main_page">
        <img className="rankytanktitle" src={wwwrankytanktitle} />
        <h1 className="title">Reporting – Your Voice Matters!</h1>

        <p className="ptext">
          We want to ensure that everyone feels safe, heard, and satisfied when using our app. That’s why we’ve made it easy for you to share your feedback with
          us. Whether it’s a suggestion, a concern, or just something you love, we value every message.
        </p>

        <div className="textcontainer">
          <h2 className="text">With our in-app feedback system, you can do a report of a certain player by pressing the button below "Report player"</h2>
          <img id="img" src={reportplayer} />
        </div>
        <div className="textcontainer">
          <h2 className="text">Select type of report, write your message and send it — simple and secure!</h2>
          <img id="img" src={reportplayerdialog} />
        </div>
        <div className="textcontainer">
          <h2 className="text">
            Our team will review your submission and take action as soon as possible. We appreciate your input and are committed to making the app a better
            experience for everyone.
          </h2>
          <img id="img" src={reportplayerdialogsubmitted} />
        </div>

        <div style={{ width: '100%', borderBottom: 'solid black 3px', marginBottom: 30 }} />

        <p className="ptext">If reporting a specific player is not enough, you can send general feedback through the settings page.</p>

        <div style={{width:300}}>
          <a href="/feedback">
            <img id="img" src={feedbackbutton} />
          </a>
        </div>
      </div>

      <div className="main_page">
        <div className="footer xxx">Copyright © Woohooo 2012 - {new Date().getFullYear()}</div>
        <div className="footer xxx">
          CEO/CTO: <a href="mailto:rankytank@woohooo.dk">Nikolaj Strauss</a>
        </div>
      </div>
    </div>
  )
}

export default Reporting
