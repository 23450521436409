import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Consts from './Const'

let connection: HubConnection | null = null

const retryConnecting = async () => {
  while (true) {
    try {
      console.log('Attempting to reconnect...')
      if (connection) {
        await connection.start()
      }
      console.log('Reconnected successfully!')
      break // Exit the loop on successful reconnection
    } catch (err) {
      console.error('Reconnection failed. Retrying in 10 seconds...', err)
      await new Promise(resolve => setTimeout(resolve, 10000)) // Wait before retrying
    }
  }
}

export const setupSignalRConnection = async (): Promise<HubConnection> => {
  console.log('setupSignalRConnection')
  if (!connection) {
    connection = new HubConnectionBuilder()
      .withUrl(`${Consts.defaultBaseUrl}/hub`) // Replace with your API URL
      .withAutomaticReconnect([0, 2000, 10000, 30000])
      .configureLogging(LogLevel.Information)
      .build()

    connection.onclose(async error => {
      console.error('SignalR connection closed. Starting manual reconnection...')
      await retryConnecting()
    })

    // Start the connection
    try {
      await retryConnecting()
      console.log('SignalR Connected.')
    } catch (err) {
      console.error('Error while starting SignalR connection: ', err)
    }
  }

  return connection
}

export const getSignalRConnection = (): HubConnection | null => {
  return connection
}
