import './privacy.css'
import wwwwoohooo from './assets/home/wwwwoohooo.png'
import privacy1 from './assets/home/privacy1.png'
import privacy2 from './assets/home/privacy2.png'
import privacy3 from './assets/home/privacy3.png'
import privacy4 from './assets/home/privacy4.png'
import privacy5 from './assets/home/privacy5.png'
import privacy6 from './assets/home/privacy6.png'
import wwwrankytanktitle from './assets/home/wwwrankytanktitle.png'

function Privacy() {
  return (
    <div className="page">
      <div className="main_page">
        <a href="/">
          <img className="woohooologo" src={wwwwoohooo} />
        </a>
      </div>

      <div className="main_page">
        <img className="rankytanktitle" src={wwwrankytanktitle} />
        <h1 className="title">Privacy policy</h1>

        <p className="ptext">
          Since this is a statistics app, entered data on players, matches etc. is collected. But you can always remove yourself as a player/user/account. As
          described below you are able to see how this is done.
        </p>

        <div className="textcontainer">
          <h2 className="text">Press your profile picture on the dashboard in order to view you player/user/account.</h2>
          <img id="img" src={privacy1} />
        </div>
        <div className="textcontainer">
          <h2 className="text">Press the pencil in the upper right corner to edit the player/user/account.</h2>
          <img id="img" src={privacy2} />
        </div>
        <div className="textcontainer">
          <h2 className="text">Scroll to the bottom of the "Edit player" page and press the "Delete and clear all".</h2>
          <img id="img" src={privacy3} />
        </div>
        <div className="textcontainer">
          <h2 className="text">
            As written the "Delete and clear all" button removes everything relating to the player/user/account and related information will be anonymized.
          </h2>
          <img id="img" src={privacy4} />
        </div>
        <div className="textcontainer">
          <h2 className="text">
            Matches etc relating to the removed player/user/account will still be visible, but the player/user/account will be listed as [DELETED] and
            everything is anonymized.
          </h2>
          <img id="img" src={privacy5} />
        </div>
        <div className="textcontainer">
          <h2 className="text">
            Again matches with the player etc will still be visible, but the player will be listed as [DELETED]. The points the player had is still intact, but
            isn't related to a person/account at all.
          </h2>
          <img id="img" src={privacy6} />
        </div>
      </div>

      <div className="main_page">
        <div className="footer xxx">Copyright © Woohooo 2012 - {new Date().getFullYear()}</div>
        <div className="footer xxx">
          CEO/CTO: <a href="mailto:rankytank@woohooo.dk">Nikolaj Strauss</a>
        </div>
      </div>
    </div>
  )
}

export default Privacy
