import TopPlayerForPeriod, { ITopPlayerForPeriod } from './TopPlayerForPeriod'
import { useEffect, useRef, useState } from 'react'
import './App.css'
import { gsap } from 'gsap'
import Consts from './Const'

interface ITopPlayersForPeriodProps {
  organizationId: string
  activityType: number
  year?: number
  week?: number
  baseUrl: string
  amount?: number
  latestHistoryId: string
  showMedals?: boolean
  showAnim: boolean
  resetAnim?: boolean
}

const padNumberWithZero = (num: number) => (num < 10 ? `0${num}` : `${num}`)
let xxxflag = false

function TopPlayersForPeriod({
  organizationId,
  activityType,
  year,
  week,
  baseUrl,
  latestHistoryId,
  amount,
  showMedals,
  showAnim,
  resetAnim,
}: ITopPlayersForPeriodProps) {
  const [topPlayersForPeriod, setTopPlayersForPeriodX] = useState<ITopPlayerForPeriod[] | undefined>()
  const [topPlayersForPeriodNext, setTopPlayersForPeriodNextX] = useState<ITopPlayerForPeriod[] | undefined>()
  const [topPlayersForPeriodWasChanged, setTopPlayersForPeriodWasChanged] = useState(0)
  const [topPlayersForPeriodNextWasChanged, setTopPlayersForPeriodNextWasChanged] = useState(0)

  const setTopPlayersForPeriod = (data: ITopPlayerForPeriod[] | undefined) => {
    setTopPlayersForPeriodX(data)
    setTopPlayersForPeriodWasChanged(x => x + 1)
  }

  const setTopPlayersForPeriodNext = (data: ITopPlayerForPeriod[] | undefined) => {
    setTopPlayersForPeriodNextX(data)
    setTopPlayersForPeriodNextWasChanged(x => x + 1)
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const playersRef = useRef<HTMLDivElement>(null)
  const measurePlayersRef = useRef<HTMLDivElement>(null)
  const refFirstPlayerOfSecondList = useRef<HTMLDivElement>(null)

  const [resizeChanged, setResizeChanged] = useState(0)

  const [measuresRefresh, setMeasuresRefresh] = useState(0)
  const doMeasures = () => {
    //console.log('doMeasures called')
    setMeasuresRefresh(x => x + 1)
  }

  const [containerDimensions, setContainerDimensions] = useState<{ width: number; height: number; y: number }>({
    width: 0,
    height: 0,
    y: 0,
  })
  const [playerDimensions, setPlayerDimensions] = useState<{ width: number; height: number; y: number }>({
    width: 0,
    height: 0,
    y: 0,
  })
  const [measurePlayersDimensions, setMeasurePlayersDimensions] = useState<{ width: number; height: number; y: number }>({
    width: 0,
    height: 0,
    y: 0,
  })
  const [firstPlayerOfSecondListDimensions, setFirstPlayerOfSecondListDimensions] = useState<{ width: number; height: number; y: number }>({
    width: 0,
    height: 0,
    y: 0,
  })

  useEffect(() => {
    if (containerRef.current) {
      const { width, height, x, y } = containerRef.current.getBoundingClientRect()
      if (width !== containerDimensions.width || height !== containerDimensions.height || y !== containerDimensions.y) {
        setContainerDimensions({ width, height, y })
      }
    }
    if (playersRef.current) {
      const { width, height, x, y } = playersRef.current.getBoundingClientRect()
      if (width !== playerDimensions.width || height !== playerDimensions.height || y !== playerDimensions.y) {
        setPlayerDimensions({ width, height, y })
      }
    }
    if (measurePlayersRef.current) {
      const { width, height, x, y } = measurePlayersRef.current.getBoundingClientRect()
      if (width !== measurePlayersDimensions.width || height !== measurePlayersDimensions.height || y !== measurePlayersDimensions.y) {
        setMeasurePlayersDimensions({ width, height, y })
      }
    }
    if (refFirstPlayerOfSecondList.current) {
      const { width, height, x, y } = refFirstPlayerOfSecondList.current.getBoundingClientRect()
      if (
        width !== firstPlayerOfSecondListDimensions.width ||
        height !== firstPlayerOfSecondListDimensions.height ||
        y !== firstPlayerOfSecondListDimensions.y
      ) {
        //if (!showMedals) console.log(`refFirstPlayerOfSecondList.y is just measured to ${y}`)
        setFirstPlayerOfSecondListDimensions({ width, height, y })
      }
    }
  }, [year, topPlayersForPeriod, topPlayersForPeriodNext, resizeChanged, measuresRefresh]) // Runs only once after the component mounts

  useEffect(() => {
    // const adjustDivHeight = () => {
    //   if (containerRef.current) {
    //     const viewportHeight = window.innerHeight
    //     const rect = containerRef.current.getBoundingClientRect()
    //     const divHeight = rect.height
    //     const topOffset = rect.top

    //     // Adjust height to make the div's bottom align with the viewport height
    //     const remainingHeight = viewportHeight - (topOffset + divHeight)
    //     containerRef.current.style.marginBottom = `${remainingHeight}px`
    //   }
    // }

    const onResizeChanged = () => {
      setResizeChanged(x => x + 1)
    }

    // adjustDivHeight() // Adjust on mount
    window.addEventListener('resize', onResizeChanged) // Adjust on resize

    return () => {
      window.removeEventListener('resize', onResizeChanged) // Cleanup
    }
  }, [])

  //   const insertAtIndex = <T,>(arr: T[], index: number, item: T) => {
  //     arr.splice(index, 0, item) // '0' means no elements are removed, just insert the item
  //     return arr
  //   }

  //   const removeAtIndex = <T,>(arr: T[], index: number) => {
  //     arr.splice(index, 1) // '1' means remove 1 element at the given index
  //     return arr
  //   }

  const [doAnim, setDoAnim] = useState(false)

  const paddingTop = 0.025 // of viewportWidth, must match the TopPlayersForPeriod-container's padding top

  const [animStatus, setAnimStatus] = useState<'waitForReset' | 'waitForResetWithoutAnim' | 'waitForRunning' | 'running'>('waitForRunning')
  const [animRefresh, setAnimRefresh] = useState(0)

  useEffect(() => {
    if (!showAnim || !topPlayersForPeriod?.length) return

    //if (!showMedals) console.log(`useEffect-anim: firstPlayerOfSecondListDimensions.y=${firstPlayerOfSecondListDimensions.y}`)
    const visibleContainer = window.innerHeight - containerDimensions.y
    const yAnimMax = visibleContainer - measurePlayersDimensions.height - 100

    let anim1: gsap.core.Tween | undefined
    let anim2: gsap.core.Tween | undefined
    let anim3: gsap.core.Tween | undefined
    let anim4: gsap.core.Tween | undefined
    let anim5: gsap.core.Tween | undefined

    const killAll = () => {
      anim1?.kill()
      anim2?.kill()
      anim3?.kill()
      anim4?.kill()
      anim5?.kill()
    }

    if (!showMedals) {
      //console.log(`yAnimMax = ${yAnimMax}`)
    }
    if (yAnimMax < 0) {
      setDoAnim(true)

      const viewportWidth = window.innerWidth

      // let rect:DOMRect | undefined
      // if (refFirstPlayerOfSecondList.current) {
      //   // Temporarily ignore transforms to get the original bounding rect
      //   const originalRect = gsap.getProperty(refFirstPlayerOfSecondList.current, "transform");
      //   gsap.set(refFirstPlayerOfSecondList.current, { clearProps: "transform" });

      //   rect = refFirstPlayerOfSecondList.current.getBoundingClientRect();
      //   console.log("Original Rect:", rect);

      //   // Restore the transform property
      //   gsap.set(refFirstPlayerOfSecondList.current, { transform: originalRect });
      // }

      let y = -(firstPlayerOfSecondListDimensions.y - containerDimensions.y - viewportWidth * paddingTop)
      //if (!showMedals) console.log(`at ${animStatus}.. y=${y}  firstPlayerOfSecondListDimensions.y=${firstPlayerOfSecondListDimensions.y}`)

      const updateTopPlayersForPeriodByNext = () => {
        if (!showMedals) {
          //console.log(`updateTopPlayersForPeriodByNext : topPlayersForPeriodNext.length=${topPlayersForPeriodNext?.length}`)
        }
        setTopPlayersForPeriod(topPlayersForPeriodNext)
      }

      const reset = (onComplete: () => void) => {
        anim1 = gsap.to(playersRef.current, {
          y: 0,
          opacity: xxxflag ? 1.00001 : 1.00002,
          // duration: 0,
          onComplete: () => {
            //if (!showMedals) console.log('reset complete')
            //y = -(firstPlayerOfSecondListDimensions.y - containerDimensions.y - viewportWidth * paddingTop)
            onComplete()
          },
        })
        xxxflag = !xxxflag
      }

      const resetWithoutAnim = (onComplete: () => void) => {
        anim1 = gsap.set(playersRef.current, {
          y: 0,
          opacity: xxxflag ? 1.00001 : 1.00002,
          // duration: 0,
          onComplete: () => {
            //if (!showMedals) console.log('reset without anim complete')
            //y = -(firstPlayerOfSecondListDimensions.y - containerDimensions.y - viewportWidth * paddingTop)
            onComplete()
          },
        })
        xxxflag = !xxxflag
      }
      const animate = () => {
        const currentY = (playersRef.current?.getBoundingClientRect().top ?? 0) - containerDimensions.y - viewportWidth * paddingTop
        const missingPixels = y - currentY
        const allNumbersAtSetup = firstPlayerOfSecondListDimensions.y !== 0 && containerDimensions.y !== 0
        const missingPixelsRatio = allNumbersAtSetup ? missingPixels / y : 1
        if (!showMedals) {
          //console.log(`missingPixelsRatio =${missingPixelsRatio}`)
          //console.log(`animate start : y=${y} missingPixels=${missingPixels} missingPixels=${missingPixels} ratio=${missingPixelsRatio} firstPlayerOfSecondListDimensions.y=${firstPlayerOfSecondListDimensions.y} containerDimensions.y=${containerDimensions.y}`)
          //console.log(`y = ${y}`)
        }

        anim2 = gsap.to(playersRef.current, {
          y: y, // Move to the right edge
          duration: Math.max(1, ((topPlayersForPeriod?.length ?? 0) + (topPlayersForPeriodNext?.length ?? 0) + 1) * 0.5 * missingPixelsRatio),
          // ((-yAnimMax / window.innerHeight) * 10) / (allNumbersAtSetup ? missingPixelsRatio : 1), // Duration of animation in seconds
          //repeat: -1, // Infinite repeat
          //yoyo: true, // Move back and forth
          ease: 'none', // 'power1.inOut', // Smoother easing
          //ease: 'power1.inOut',
          delay: 2,
          onComplete: () => {
            updateTopPlayersForPeriodByNext()

            setAnimStatus('waitForResetWithoutAnim')

            // anim3 = gsap.set(playersRef.current, {
            //   y: 0,
            //   onComplete: () => {
            //     if (!showMedals) {
            //       //console.log('onComplete anim')
            //     }
            //     killAll()
            //     animate()
            //   },
            // })
            // anim = gsap.to(playersRef.current, {
            //   y: 0, // Move to the right edge
            //   duration: (-yAnimMax / window.innerHeight) * 1, // Duration of animation in seconds
            //   //repeat: -1, // Infinite repeat
            //   //yoyo: true, // Move back and forth
            //   //ease: 'none', // 'power1.inOut', // Smoother easing
            //   ease: 'power1.inOut',
            //   delay: 2,
            //   onComplete: animate,
            // })
          },
        })
      }

      if (animStatus === 'waitForReset') {
        reset(() => {
          doMeasures()
          setAnimStatus('waitForRunning')
        })
      }
      if (animStatus === 'waitForResetWithoutAnim') {
        resetWithoutAnim(() => {
          doMeasures()
          setAnimStatus('waitForRunning')
        })
      } else if (animStatus === 'waitForRunning') {
        animate()
      }

      // do anim
    } else {
      // do no anim, too few players
      setDoAnim(false)

      if (playersRef.current) {
        // scroll to top, because we might have had an animation before which should be reset entitly
        anim5 = gsap.to(playersRef.current, {
          y: 0,
          opacity: xxxflag ? 1.00001 : 1.00002,
        })
      }
    }

    return () => {
      if (!showMedals) {
        //        console.log('animate kill')
      }
      killAll()
    }
  }, [animStatus, animRefresh, firstPlayerOfSecondListDimensions, topPlayersForPeriod])

  useEffect(() => {
    setAnimStatus('waitForReset')
    setAnimRefresh(x => x + 1)
    doMeasures()
  }, [containerDimensions, playerDimensions, measurePlayersDimensions, topPlayersForPeriod, topPlayersForPeriodNext])

  // useEffect(() => {
  //   const visibleContainer = window.innerHeight - containerDimensions.y
  //   const yAnimMax = visibleContainer - playerDimensions.height - 100
  //   let anim: gsap.core.Tween | undefined
  //   if (yAnimMax < 0) {
  //     const animate = () => {
  //       anim = gsap.to(playersRef.current, {
  //         y: yAnimMax, // Move to the right edge
  //         duration: (-yAnimMax / window.innerHeight) * 10, // Duration of animation in seconds
  //         //repeat: -1, // Infinite repeat
  //         //yoyo: true, // Move back and forth
  //         ease: 'none', // 'power1.inOut', // Smoother easing
  //         //ease: 'power1.inOut',
  //         delay: 10,
  //         onComplete: () => {
  //           anim = gsap.to(playersRef.current, {
  //             y: 0, // Move to the right edge
  //             duration: (-yAnimMax / window.innerHeight) * 1, // Duration of animation in seconds
  //             //repeat: -1, // Infinite repeat
  //             //yoyo: true, // Move back and forth
  //             //ease: 'none', // 'power1.inOut', // Smoother easing
  //             ease: 'power1.inOut',
  //             delay: 2,
  //             onComplete: animate,
  //           })
  //         },
  //       })
  //     }

  //     anim = gsap.to(playersRef.current, {
  //       y: 0,
  //       onComplete: animate,
  //     })

  //     return () => {
  //       anim?.kill()
  //     }
  //   }
  // }, [containerDimensions, playerDimensions, topPlayersForPeriod, resetAnim])

  useEffect(() => {
    if (!year) return

    fetch(
      `${baseUrl}/api/topplayersforyear?organizationId=${organizationId}&activityType=${activityType}&period=${year}00${padNumberWithZero(
        week ?? 0,
      )}&amount=${amount}`,
    )
      .then(res => {
        return res.json()
      })
      .then(dataX => {
        const data = dataX //showMedals ? dataX : Consts.useStubData1 ? Consts.stubData1 : Consts.stubData2

        if (!showMedals) {
          //console.log(`setting data.length=${data.length}`)
        }

        const topPlayersForYear = data as ITopPlayerForPeriod[]
        if (!doAnim || topPlayersForPeriod === undefined) {
          setTopPlayersForPeriod(topPlayersForYear)
          setTopPlayersForPeriodNext(topPlayersForYear)
        } else {
          setTopPlayersForPeriod(topPlayersForYear) // Nu da vi resetter til toppen når der er ændret på en spiller eller en kamp, så giver det ingen mening at de er forskellige
          setTopPlayersForPeriodNext(topPlayersForYear)
        }
      })
      .catch(() => {
        setTopPlayersForPeriod(undefined)
        setTopPlayersForPeriodNext(undefined)
      })
  }, [year, week, baseUrl, amount, latestHistoryId, resetAnim])

  if (!year || !topPlayersForPeriod || !topPlayersForPeriodNext) return null

  if (!topPlayersForPeriod.length) {
    return <div className="TopPlayersForPeriod-empty-container">No one has played yet this {week ? 'week' : 'year'}...</div>
  }

  // if (!showMedals) {
  //   console.log(`topPlayersForPeriod.length = ${topPlayersForPeriod.length}`)
  //   console.log(`topPlayersForPeriodNext.length = ${topPlayersForPeriodNext.length}`)
  // }

  return (
    <div ref={containerRef} className="TopPlayersForPeriod-container" style={{}}>
      <div ref={measurePlayersRef} className="TopPlayersForPeriod-container-anim" style={{ position: 'absolute', opacity: 0 }}>
        {topPlayersForPeriod.map((data, dataIdx) => {
          return <TopPlayerForPeriod key={data.Id} data={data} position={dataIdx + 1} showMedals={showMedals} />
        })}
      </div>
      <div
        ref={playersRef}
        className="TopPlayersForPeriod-container-anim"
        onLoad={() => {
          //if (!showMedals) console.log('call doMeasures')
          doMeasures()
        }}
      >
        {topPlayersForPeriod.map((data, dataIdx) => {
          return (
            <TopPlayerForPeriod
              key={`Previous_${topPlayersForPeriodWasChanged}_${topPlayersForPeriodNextWasChanged}_${data.Id}`}
              data={data}
              position={dataIdx + 1}
              showMedals={showMedals}
            />
          )
        })}
        {doAnim ? (
          <div>
            {/* For spacing */}
            <TopPlayerForPeriod data={undefined} position={0} />
            {topPlayersForPeriodNext.map((data, dataIdx) => {
              return (
                <TopPlayerForPeriod
                  ref={dataIdx === 0 ? refFirstPlayerOfSecondList : undefined}
                  key={`Next_${topPlayersForPeriodWasChanged}_${topPlayersForPeriodNextWasChanged}_${data.Id}`}
                  data={data}
                  position={dataIdx + 1}
                  showMedals={showMedals}
                />
              )
            })}
          </div>
        ) : undefined}
      </div>
    </div>
  )
}

export default TopPlayersForPeriod
