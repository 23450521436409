import { useEffect } from "react";

// export const useImagePreloader = (imageUrls: string[]) => {
//     useEffect(() => {
//         imageUrls.forEach((url) => {
//             const img = new Image();
//             img.src = url;
//         });
//     }, [imageUrls]);
// };

const importImage = (image: string) => import(`${image}`);

export const useImagePreloader = (imageUrls: string[]) => {
    useEffect(() => {
        imageUrls.forEach(async (url) => {
            try {
                const img = await importImage(url); // Dynamically import the image
                const image = new Image();
                image.src = img.default; // `img.default` holds the actual URL for webpack
                image.onload = () => {
                    console.log(`Image preloaded: ${img.default}`);
                };
                image.onerror = () => {
                    console.error(`Failed to preload image: ${img.default}`);
                };
            } catch (error) {
                console.error(`Error loading image: ${url}`, error);
            }
        });
    }, imageUrls);
};
