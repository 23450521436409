import { useSearchParams } from 'react-router-dom';

/**
 * Custom hook to get query parameters in a case-insensitive manner.
 * @returns A case-insensitive map of query parameters.
 */
function useCaseInsensitiveSearchParams(): Record<string, string> {
  const [searchParams] = useSearchParams();

  // // Convert searchParams to a case-insensitive map
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key.toLowerCase()] = value;
  });

  return params;
}

export default useCaseInsensitiveSearchParams;