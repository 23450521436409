import { gsap } from 'gsap'
import moment from 'moment'
import './UgeDagPage.css'
import { RefObject, useEffect, useRef, useState } from 'react'
import { title } from 'process'
import { Language } from '@mui/icons-material'
import Consts from './Const'

let xxxflag = false

const mondayAnim = (ugedagRef: RefObject<HTMLDivElement>) => {
  let anim: gsap.core.Tween | undefined
  const animate = () => {
    anim = gsap.to(ugedagRef.current, {
      x: '+=5',
      rotationX: '+=5',
      yoyo: true,
      repeat: -1,
      duration: 0.1,
    })
  }

  animate()

  return () => {
    anim?.kill()
  }
}

const tuesdayAnim = (ugedagRef: RefObject<HTMLDivElement>) => {
  let anim: gsap.core.Tween | undefined
  const animate = () => {}

  animate()

  return () => {
    anim?.kill()
  }
}

const wednesdayAnim = (ugedagRef: RefObject<HTMLDivElement>) => {
  let anim: gsap.core.Tween | undefined
  const animate = () => {}

  animate()

  return () => {
    anim?.kill()
  }
}

const thursdayAnim = (ugedagRef: RefObject<HTMLDivElement>) => {
  let anim: gsap.core.Tween | undefined
  const animate = () => {}

  animate()

  return () => {
    anim?.kill()
  }
}

const fridayAnim = (ugedagRef: RefObject<HTMLDivElement>) => {
  let anim1: gsap.core.Tween | undefined
  let anim2: gsap.core.Tween | undefined
  let anim3: gsap.core.Tween | undefined
  let anim4: gsap.core.Tween | undefined
  let anim5: gsap.core.Tween | undefined
  let anim6: gsap.core.Tween | undefined
  let anim7: gsap.core.Tween | undefined

  const killAll = () => {
    anim1?.kill()
    anim2?.kill()
    anim3?.kill()
    anim4?.kill()
    anim5?.kill()
    anim6?.kill()
    anim7?.kill()
  }

  const animBackAndFourth = (duration: gsap.TweenValue | undefined, onComplete: () => void) => {
    anim1 = gsap.to(ugedagRef.current, {
      rotateZ: -15,
      duration: duration ?? 1,
      //delay: 1,
      scale: 1,

      //repeat: -1, // Infinite repeat
      //yoyo: true, // Move back and forth
      //ease: 'none', // 'power1.inOut', // Smoother easing
      ease: 'power1.inOut',
      onComplete: () => {
        anim2 = gsap.to(ugedagRef.current, {
          rotateZ: 15,
          duration: 2,

          //repeat: -1, // Infinite repeat
          //yoyo: true, // Move back and forth
          //ease: 'none', // 'power1.inOut', // Smoother easing
          ease: 'power1.inOut',
          onComplete,
        })
      },
    })
  }

  const spin = (onComplete: () => void) => {
    anim3 = gsap.to(ugedagRef.current, {
      rotateZ: -360 * 5,
      duration: 2,
      scale: 0.6,

      //repeat: -1, // Infinite repeat
      //yoyo: true, // Move back and forth
      //ease: 'none', // 'power1.inOut', // Smoother easing
      ease: 'power1.inOut',
      onComplete: () => {
        anim4 = gsap.to(ugedagRef.current, {
          duration: 1,
          scale: 1,

          //repeat: -1, // Infinite repeat
          //yoyo: true, // Move back and forth
          //ease: 'none', // 'power1.inOut', // Smoother easing
          ease: 'power1.inOut',
          onComplete,
        })
      },
    })
  }

  const reset = (onComplete: () => void) => {
    anim5 = gsap.set(ugedagRef.current, {
      rotateZ: 0,
      onComplete,
    })
  }

  const animate = () => {
    reset(() => {
      animBackAndFourth(undefined, () => {
        animBackAndFourth(2, () => {
          animBackAndFourth(2, () => {
            animBackAndFourth(2, () => {
              animBackAndFourth(2, () => {
                spin(animate)
              })
            })
          })
        })
      })
    })
  }

  animate()

  return () => {
    killAll()
  }
}

const saturdayAnim = (ugedagRef: RefObject<HTMLDivElement>) => {
  let anim: gsap.core.Tween | undefined
  const animate = () => {}

  animate()

  return () => {
    anim?.kill()
  }
}

const sundayAnim = (ugedagRef: RefObject<HTMLDivElement>) => {
  let anim: gsap.core.Tween | undefined
  const animate = () => {}

  animate()

  return () => {
    anim?.kill()
  }
}

interface IUgeDagTitleAndLanguage {
  title: string
  language: string
  fontSize?: string
}

const getWeekDayTitleByLanguage = (weekDay: number) => {
  if (weekDay === 1) {
    return [
      {
        title: 'Mandag! 😱',
        language: 'Dansk',
      },
      {
        title: 'Monday! 😱',
        language: 'Engelsk',
      },
      {
        title: 'Maandag! 😱',
        language: 'Hollandsk',
      },
      {
        title: 'Montag! 😱',
        language: 'Tysk',
      },
      {
        title: 'Lundi! 😱',
        language: 'Fransk',
      },
      {
        title: 'Lunes! 😱',
        language: 'Spansk',
      },
      {
        title: 'Lunedì! 😱',
        language: 'Italiensk',
      },
      {
        title: 'Maanantai! 😱',
        language: 'Finsk',
        fontSize: '20vh',
      },
    ] as IUgeDagTitleAndLanguage[]
  }

  if (weekDay === 2) {
    return [
      {
        language: 'Dansk',
        title: 'Tirsdag 🤔',
      },
      {
        language: 'Engelsk',
        title: 'Tuesday 🤔',
      },
      {
        language: 'Hollandsk',
        title: 'Dinsdag 🤔',
      },
      {
        language: 'Tysk',
        title: 'Dienstag 🤔',
      },
      {
        language: 'Fransk',
        title: 'Mardi 🤔',
      },
      {
        language: 'Spansk',
        title: 'Martes 🤔',
      },
      {
        language: 'Italiensk',
        title: 'Martedì 🤔',
      },
      {
        language: 'Finsk',
        title: 'Tiistai 🤔',
        fontSize: '20vh',
      },
    ] as IUgeDagTitleAndLanguage[]
  }

  if (weekDay === 3) {
    return [
      {
        language: 'Dansk',
        title: 'Onsdag 😅',
      },
      {
        language: 'Engelsk',
        title: 'Wednesday 😅',
      },
      {
        language: 'Hollandsk',
        title: 'Woensdag 😅',
      },
      {
        language: 'Tysk',
        title: 'Mittwoch 😅',
      },
      {
        language: 'Fransk',
        title: 'Mercredi 😅',
      },
      {
        language: 'Spansk',
        title: 'Miércoles 😅',
      },
      {
        language: 'Italiensk',
        title: 'Mercoledì 😅',
      },
      {
        language: 'Finsk',
        title: 'Keskiviikko 😅',
        fontSize: '20vh',
      },
    ] as IUgeDagTitleAndLanguage[]
  }

  if (weekDay === 4) {
    return [
      {
        language: 'Dansk',
        title: 'Torsdag 🙂',
      },
      {
        language: 'Engelsk',
        title: 'Thursday 🙂',
      },
      {
        language: 'Hollandsk',
        title: 'Donderdag 🙂',
      },
      {
        language: 'Tysk',
        title: 'Donnerstag 🙂',
      },
      {
        language: 'Fransk',
        title: 'Jeudi 🙂',
      },
      {
        language: 'Spansk',
        title: 'Jueves 🙂',
      },
      {
        language: 'Italiensk',
        title: 'Giovedì 🙂',
      },
      {
        language: 'Finsk',
        title: 'Torstai 🙂',
        fontSize: '20vh',
      },
    ] as IUgeDagTitleAndLanguage[]
  }

  if (weekDay === 5) {
    return [
      {
        language: 'Dansk',
        title: 'Fredag 🥳',
      },
      {
        language: 'Engelsk',
        title: 'Friday 🥳',
      },
      {
        language: 'Hollandsk',
        title: 'Vrijdag 🥳',
      },
      {
        language: 'Tysk',
        title: 'Freitag 🥳',
      },
      {
        language: 'Fransk',
        title: 'Vendredi 🥳',
      },
      {
        language: 'Spansk',
        title: 'Viernes 🥳',
      },
      {
        language: 'Italiensk',
        title: 'Venerdì 🥳',
      },
      {
        language: 'Finsk',
        title: 'Perjantai 🥳',
        fontSize: '20vh',
      },
    ] as IUgeDagTitleAndLanguage[]
  }

  if (weekDay === 6) {
    return [
      {
        language: 'Dansk',
        title: 'Lørdag 😎',
      },
      {
        language: 'Engelsk',
        title: 'Saturday 😎',
      },
      {
        language: 'Hollandsk',
        title: 'Zaterdag 😎',
      },
      {
        language: 'Tysk',
        title: 'Samstag 😎',
      },
      {
        language: 'Fransk',
        title: 'Samedi 😎',
      },
      {
        language: 'Spansk',
        title: 'Sábado 😎',
      },
      {
        language: 'Italiensk',
        title: 'Sabato 😎',
      },
      {
        language: 'Finsk',
        title: 'Lauantai 😎',
        fontSize: '20vh',
      },
    ] as IUgeDagTitleAndLanguage[]
  }

  if (weekDay === 0) {
    return [
      {
        language: 'Dansk',
        title: 'Søndag 🛋️',
      },
      {
        language: 'Engelsk',
        title: 'Sunday 🛋️',
      },
      {
        language: 'Hollandsk',
        title: 'Zondag 🛋️',
      },
      {
        language: 'Tysk',
        title: 'Sonntag 🛋️',
      },
      {
        language: 'Fransk',
        title: 'Dimanche 🛋️',
      },
      {
        language: 'Spansk',
        title: 'Domingo 🛋️',
      },
      {
        language: 'Italiensk',
        title: 'Domenica 🛋️',
      },
      {
        language: 'Finsk',
        title: 'Sunnuntai 🛋️',
        fontSize: '20vh',
      },
    ] as IUgeDagTitleAndLanguage[]
  }

  return [] as IUgeDagTitleAndLanguage[]
}

// const getWeekDayTitle = (weekDay: number) => {
//   switch (weekDay) {
//     case 1:
//       return 'Mandag! 😱'
//     case 2:
//       return 'Tirsdag 🤔'
//     case 3:
//       return 'Onsdag 😅'
//     case 4:
//       return 'Torsdag 🙂'
//     case 5:
//       return 'Fredag 🥳'
//     case 6:
//       return 'Lørdag 😎'
//     case 7:
//       return 'Søndag 🛋️'
//     default:
//       return '???'
//   }
// }

const getWeekdayJokes = (weekDay: number) => {
  switch (weekDay) {
    case 1:
      return [
        'Mandag er som den der ven, der altid inviterer sig selv til festen – man ville helst være foruden.',
        'Mandag er den første bakke på bjergbestigningen, og du har allerede åndedrætsproblemer.',
        'Mandag er som den der opvågning, hvor du føler, du har sovet for længe, og nu skal du tage på arbejde.',
        'Mandag er den dag, man indser, at weekenden var for kort til at gøre alt, man havde planlagt.',
        'Mandag er som at få en kæmpe regning efter en fed weekend – du vidste, det ville komme.',
        'Mandag er den dag, du indser, at du nok skulle have planlagt weekenden lidt bedre.',
        "Mandag er den dag, hvor man spørger sig selv: 'Var det virkelig nødvendigt at stå op i dag?'",
        'Mandag er som at åbne en bog, hvor man allerede har læst første kapitel, men det føles som om, man starter på et nyt.',
        'Mandag er som en lang kø, der aldrig ser ud til at ende, men du er bare nødt til at blive stående.',
        'Mandag er den dag, hvor du indser, at du har for mange e-mails og for lidt energi.',
        'Mandag er som at tage en kold dukkert – du havde ikke lyst, men du ved, du skal.',
        'Mandag er som en kop kold kaffe – den er ikke rigtig, hvad du havde håbet på, men du er nødt til at drikke den.',
        'Mandag er som en vækkeur, der ringer en time for tidligt, og du kan ikke finde snooze-knappen.',
        "Mandag er som et stempel på din pande, der siger: 'Det var en god weekend, men nu skal vi tilbage til virkeligheden.'",
        'Mandag er den dag, du leder efter en vej ud, men indser, du har glemt at tage fri.',
      ]
    case 2:
      return [
        'Tirsdag er som en første date, hvor man prøver at finde ud af, om ugen er værd at fortsætte.',
        'Tirsdag er, når du er halvvejs til at blive irriteret, men stadig holder dig i skak.',
        'Tirsdag er som at gå i skole, når du virkelig gerne vil være hjemme.',
        'Tirsdag er den dag, hvor man stadig håber på, at fredagen kommer hurtigt, men indser, at den er lang væk.',
        'Tirsdag er som en kop kaffe, der er lidt for stærk – du er ikke helt klar, men du tager den alligevel.',
        'Tirsdag er den dag, man forsøger at få noget gjort, men man kan ikke rigtig finde motivationen.',
        'Tirsdag er som en værktøjskasse, hvor du håber, du har det rigtige værktøj til at komme igennem dagen.',
        'Tirsdag er som at starte på et puslespil – du er i gang, men det føles som om det tager længere tid, end du troede.',
        "Tirsdag er den dag, hvor du kigger på kalenderen og siger: 'Er det virkelig kun tirsdag?'",
        'Tirsdag er som et forsinket tog – du er på vej, men du ved ikke, hvornår du når frem.',
        'Tirsdag er den dag, hvor du prøver at finde en hurtig løsning på alt, men ender med at finde nye problemer.',
        'Tirsdag er som at sidde i et langt møde – du ved, der er noget på den anden side, men du er langt fra det.',
        'Tirsdag er som et kapitel i en bog, du ikke kan komme igennem, men du ved, der er en god slutning.',
        'Tirsdag er den dag, hvor du håber, at ugen snart tager sig sammen og bliver lidt mere spændende.',
        'Tirsdag er som en hård træning – det gør ondt, men du ved, det er godt for dig på længere sigt.',
      ]
    case 3:
      return [
        'Onsdag er som en midtvejsrapport – du har ikke noget at vise endnu, men du er halvvejs.',
        "Onsdag er den dag, hvor du begynder at tale om 'weekendplaner', men ved, at du stadig har meget tilbage.",
        'Onsdag er som en skør bil – du er på vej, men noget føles stadig lidt off.',
        'Onsdag er den dag, hvor du tæller timerne til weekend, men du har stadig ikke helt fundet ud af, hvad du laver.',
        'Onsdag er som en film, hvor du kan mærke, at du er midt i handlingen, men du vil have slutningen hurtigt.',
        'Onsdag er som en pauseknap – du kan tage en lille pause, men du ved, du skal videre snart.',
        'Onsdag er den dag, hvor du overvejer at tage ferie fra hverdagen, men ikke rigtig ved, hvordan du skal få det til at ske.',
        'Onsdag er som at være på vej til et eventyr, men du er ikke helt sikker på, om du er klar.',
        'Onsdag er den dag, hvor du opdager, at du er halvvejs til weekend, men halvdelen af arbejdet er stadig foran dig.',
        'Onsdag er som en uventet opkald – du er ikke helt forberedt, men du tager det alligevel.',
        'Onsdag er som et træningspas, hvor du allerede har udmattet dig selv, men du er kun lige begyndt.',
        "Onsdag er den dag, du spørger: 'Er det onsdag allerede?' og tænker, at det er for tidligt at være midt i ugen.",
      ]
    case 4:
      return [
        'Torsdag er den dag, hvor du prøver at overbevise dig selv om, at du er tættere på weekend, end du virkelig er.',
        'Torsdag er som at være på en lang række i et supermarked – du er næsten ved kassen, men ikke helt.',
        'Torsdag er den dag, hvor du har givet op på ugen, men du ved, der er lidt arbejde tilbage.',
        'Torsdag er som en dejlig middag, du har ventet på, men du kan mærke, du er mæt, før den er færdig.',
        'Torsdag er som at stå på en stige – du er tættere på toppen, men stadig ikke helt der.',
        "Torsdag er den dag, hvor du spørger dig selv: 'Er der ikke et hurtigere vej til weekend?'",
        'Torsdag er som at pakke til ferie – du glæder dig, men du er ikke helt klar.',
        'Torsdag er som at finde den sidste snack i skuffen – du ved, du kan klare det, men det er næsten for sent.',
        'Torsdag er den dag, hvor du begynder at mærke weekendens magi, men du er stadig i gang med arbejdet.',
        'Torsdag er som at være på en lang rejse – du er tættere på målet, men du har stadig lidt tid tilbage.',
        'Torsdag er den dag, hvor du tror, du har styr på ugen, men den giver dig stadig et par overraskelser.',
        'Torsdag er som at komme igennem et maraton – du er udmattet, men du ved, at der er lidt mere at gå.',
      ]
    case 5:
      return [
        'Fredag er den dag, du føler, at du har vundet i lotteriet – du har fri!',
        'Fredag er som den sidste bid af en god middag – du har ventet på den hele ugen.',
        'Fredag er som at finde din yndlingssang på radioen – alt føles lidt lettere.',
        'Fredag er den dag, hvor du stopper med at arbejde og begynder at planlægge din weekend.',
        'Fredag er som den første ferie-dag – alt er muligt, og du er klar til at nyde det.',
        'Fredag er som en god fest – du har ventet hele ugen, og nu er det tid til at give den gas.',
        'Fredag er den dag, hvor du pakker sammen, og selv computeren virker lettere at bære.',
        'Fredag er den dag, hvor du føler, at du har gjort dit bedste, og nu er det tid til at tage en pause.',
        'Fredag er som at finde den sidste skive pizza i fryseren – det føles som en gave.',
      ]
    case 6:
      return [
        'Lørdag er som et åbent landkort – du kan gå hvorhen du vil, men du ved, du vil ende på sofaen.',
        'Lørdag er som at have ferie uden at være langt væk hjemmefra.',
        'Lørdag er den dag, hvor du beslutter dig for at lave noget, men bliver distraheret af at lave ingenting.',
        'Lørdag er som at være på en skattejagt – du ved, du vil finde noget spændende, men du starter langsomt.',
        'Lørdag er den dag, hvor du indser, at din kalender ikke har noget på sig, og du nyder hvert sekund.',
        'Lørdag er som en gratis billet til at gøre hvad som helst – eller ikke gøre noget overhovedet.',
        'Lørdag er som den perfekte feriedag – du har alle muligheder foran dig, men ingen planer.',
        'Lørdag er som at være i en drøm – alt føles lidt mere afslappet, og du kan gøre det hele.',
        'Lørdag er den dag, du tænker på at tage på eventyr og ender med at tage en lur.',
        'Lørdag er som at finde din yndlingsfilm på Netflix – du ved, det bliver en god dag.',
      ]
    case 0:
      return [
        'Søndag er den dag, hvor du mærker, at du har fået nok af afslapning – men du ved, du skal nyde det.',
        'Søndag er som en langsom strøm – du vil gerne være produktiv, men du er for afslappet til det.',
        'Søndag er den dag, hvor du tænker, at du kunne starte på en opgave, men ender med at lave noget helt andet.',
        'Søndag er som en lang middag – du prøver at få noget ud af den, men du ved, at slutningen er tættere på, end du vil indrømme.',
        'Søndag er den dag, du elsker at være hjemme, men du ved, at du snart skal ud af døren til arbejde.',
        'Søndag er den dag, hvor du overvejer at starte ugen med et positivt mindset, men du ved, at det hurtigt vil falde fra hinanden.',
      ]
    default:
      return '???'
  }
}

const getWeekdayJoke = (weekDay: number) => {
  const jokes = getWeekdayJokes(weekDay)
  if (!jokes.length) return undefined
  if (jokes.length === 1) return jokes[0]
  return jokes[Math.floor(Math.random() * jokes.length)]
}

const specialDates = [
  {
    date: moment([2024, 11, 6]),
    titlesAndLanguages: [
      {
        title: 'Julefrokost! 💃🕺🎉',
        language: '',
        fontSize: '18vh',
      },
    ] as IUgeDagTitleAndLanguage[],
    fontWeight: 'bold',
  },
]

const testIfPageIsUp = async () => {
  try {
    const response = await fetch(`${Consts.defaultBaseUrl}/keepalive?siteversion=${encodeURIComponent(Consts.REQUIRED_SITE_VERSION)}`)

    if (response.ok) {
      const content = await response.text()
      return content.length > 0
    }
  } catch {}
  return false
}

const UgeDagPage = () => {
  const ugedagRef = useRef<HTMLDivElement>(null)

  const [weekday, setWeekday] = useState(moment().weekday())
  const [waitingToReloadPage, setWaitingToReloadPage] = useState(false)
  const [refreshPage, setRefreshPage] = useState(0)

  useEffect(() => {
    const i = setInterval(async () => {
      const newWeekday = moment().weekday()
      if (newWeekday !== weekday || waitingToReloadPage) {
        if (await testIfPageIsUp()) {
          window.location.reload()
        } else {
          setWaitingToReloadPage(true)
        }

        setWeekday(newWeekday)
      }
    }, 60000)

    return () => {
      clearInterval(i)
    }
  }, [waitingToReloadPage, weekday])

  useEffect(() => {
    const i2 = setInterval(() => {
      setRefreshPage(x => x + 1)
    }, 60000)

    return () => {
      clearInterval(i2)
    }
  }, [])

  useEffect(() => {
    // Disable scrolling by hiding overflow
    document.body.style.overflow = 'hidden'

    return () => {
      // Re-enable scrolling when the component unmounts
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    let animReset: gsap.core.Tween | undefined
    let endFunction = () => {
      animReset?.kill()
    }

    const startAnim = () => {
      switch (weekday) {
        case 0:
          endFunction = sundayAnim(ugedagRef)
          break
        case 1:
          endFunction = mondayAnim(ugedagRef)
          break
        case 2:
          endFunction = tuesdayAnim(ugedagRef)
          break
        case 3:
          endFunction = wednesdayAnim(ugedagRef)
          break
        case 4:
          endFunction = thursdayAnim(ugedagRef)
          break
        case 5:
          endFunction = fridayAnim(ugedagRef)
          break
        case 6:
          endFunction = saturdayAnim(ugedagRef)
          break
        default:
          endFunction = () => {}
          break
      }
    }

    animReset = gsap.set(ugedagRef.current, {
      x: 0,
      rotationX: 0,
      rotateZ: 0,
      scale: 1,
      opacity: xxxflag ? 1.00001 : 1.00002, // hack to ensure the animation does anything, if x and rotationX already has those values the onComplete isnt called
      onComplete: () => startAnim(),
    })

    xxxflag = !xxxflag

    return endFunction
  }, [weekday])

  const specialDate = specialDates.find(x => x.date.startOf('day').isSame(moment().startOf('day')))
  const weekdayAndLanguages = specialDate?.titlesAndLanguages ?? getWeekDayTitleByLanguage(weekday)

  if (!weekdayAndLanguages.length) return null

  const weekdayAndLanguage = weekdayAndLanguages[Math.floor(Math.random() * weekdayAndLanguages.length)]
  const fontSize = weekdayAndLanguage?.fontSize ?? '30vh'
  const fontWeight = specialDate?.fontWeight ?? '30vh'

  const joke = getWeekdayJoke(weekday)

  return (
    <div className="container">
      <div ref={ugedagRef} className="ugedag" style={{ color: 'blue', fontSize, fontWeight }}>
        {weekdayAndLanguage.title}
      </div>
      {weekdayAndLanguage.language.length && <div className="ugedag-language">({weekdayAndLanguage.language})</div>}
      {joke && <div className="ugedag-joke">{joke}</div>}
    </div>
  )
}

export default UgeDagPage
