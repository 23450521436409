import { ITopPlayerForPeriod } from './TopPlayerForPeriod'
import { useEffect, useState } from 'react'
import './App.css'
import moment from 'moment'
import TopPlayerForLastYear from './TopPlayerForLastYear'
import './TopPlayerForLastYear.css'

interface ITopPlayersForLastYearProps {
  organizationId: string
  activityType: number
  baseUrl: string
}

function TopPlayersForLastYear({ organizationId, activityType, baseUrl }: ITopPlayersForLastYearProps) {
  const [topPlayersForLastYear, setTopPlayersForLastYear] = useState<ITopPlayerForPeriod[]>()
  const now = moment() //[2025,0,20])
  const year = now.year()
  const nowMonth = now.month()

  useEffect(() => {
    if (!year || nowMonth !== 0 /*january*/) {
      setTopPlayersForLastYear(undefined)
      return
    }

    fetch(`${baseUrl}/api/topplayersforyear?organizationId=${organizationId}&activityType=${activityType}&period=${year - 1}0000&amount=3`)
      .then(res => {
        return res.json()
      })
      .then(data => {
        setTopPlayersForLastYear(data as ITopPlayerForPeriod[])
      })
      .catch(() => {})
  }, [nowMonth])

  if (!topPlayersForLastYear?.length || !year) return <div></div>

  return (
    <div style={{ position: 'absolute', width: 100, height: 100, bottom: 0 }}>
      {topPlayersForLastYear
        .map((x, idx) => ({
          position: idx + 1,
          data: x,
        }))
        .reverse()
        .map(x => {
          return <TopPlayerForLastYear data={x.data} position={x.position} />
        })}
      <div className="LastYear-title">Winners of {year - 1}</div>
    </div>
  )
}

export default TopPlayersForLastYear
